import { flowRight } from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from '../../../common/components/runtime-context';
import { LAYOUT_TYPE_PINBOARD } from '@wix/communities-forum-client-commons/dist/src/constants/layout-types';
import Page from '../../components/page';
import CategoryListMobile from '../../components/category-list-mobile';
import CategoryListDesktop from '../../components/category-list-desktop';
import CategoryListClassic from '../../components/category-list-classic';
import { HorizontalSeparator } from '../../components/separator';
import FooterPosts from '../../components/footer-posts';
import {
  getCategories,
  getUniquePostTypesInAllCategories,
} from '../../../common/selectors/categories-selectors';
import {
  getIsViewsCountEnabled,
  getIsFooterPostsEnabled,
} from '../../selectors/app-settings-selectors';
import { getMainPageLayoutType } from '../../selectors/layout-selectors';
import forDevice from '../../hoc/for-device';
import ForumDescription from '../../components/forum-description';
import forDesktopOnly from '../../hoc/for-desktop-only';
import withDeviceType from '../../hoc/with-device-type';
import CreatePostCta from '../../components/create-post-cta';
import CreatePostButton from '../../components/create-post-button';
import EmptyLayout from '../../components/layout/empty-layout';
import LargeSpacingLayout from '../../components/layout/large-spacing-layout';
import styles from './categories-page.scss';
import { NoCategoriesEmptyState } from '../../components/no-categories-empty-states';
import { hasPaidPlans } from '../../selectors/paid-plans-selectors';
import { REDUCERS } from '../../../common/components/runtime-context/reducers';

const Layout = forDevice(EmptyLayout, LargeSpacingLayout);
const List = forDevice(CategoryListMobile, CategoryListDesktop);
const ForumDescriptionDesktopOnly = forDesktopOnly(ForumDescription);

export class CategoriesPage extends Component {
  render() {
    const {
      categories,
      layoutType,
      isViewsCountEnabled,
      isFooterPostsEnabled,
      isMobile,
      uniquePostTypesInAllCategories,
      hasPP,
    } = this.props;

    const postTypes =
      uniquePostTypesInAllCategories.length !== 1
        ? uniquePostTypesInAllCategories
        : [uniquePostTypesInAllCategories[0]];

    return (
      <Page noSpacing={isMobile}>
        {isMobile && (
          <React.Fragment>
            <ForumDescription />
            <CreatePostCta postTypes={postTypes} />
            <HorizontalSeparator isLarge />
          </React.Fragment>
        )}
        <Layout>
          {!categories.length ? (
            <NoCategoriesEmptyState hasPaidPlans={hasPP} />
          ) : (
            <React.Fragment>
              <ForumDescriptionDesktopOnly />
              {!isMobile && (
                <div className={styles.categoriesControls}>
                  <CreatePostButton postTypes={postTypes} />
                </div>
              )}
              {layoutType === LAYOUT_TYPE_PINBOARD ? (
                <List
                  isViewsCountEnabled={isViewsCountEnabled}
                  categories={categories}
                  layoutType={layoutType}
                  isFooterPostsEnabled={isFooterPostsEnabled}
                />
              ) : (
                <CategoryListClassic
                  isViewsCountEnabled={isViewsCountEnabled}
                  categories={categories}
                  isFooterPostsEnabled={isFooterPostsEnabled}
                />
              )}
              {isFooterPostsEnabled && (
                <div className={styles.footerPosts}>
                  <FooterPosts type={FooterPosts.TYPE_NEW_POSTS} prefetchPost />
                </div>
              )}
            </React.Fragment>
          )}
        </Layout>
      </Page>
    );
  }
}

CategoriesPage.propTypes = {
  categories: PropTypes.array,
  isViewsCountEnabled: PropTypes.bool.isRequired,
  layoutType: PropTypes.number,
  redirectToCategory: PropTypes.func,
  isFooterPostsEnabled: PropTypes.bool,
  hasPP: PropTypes.bool,
  isMobile: PropTypes.bool,
  uniquePostTypesInAllCategories: PropTypes.array,
};

const mapRuntimeToProps = (state, ownProps, actions, host) => ({
  categories: getCategories(state),
  layoutType: getMainPageLayoutType(state, host.style),
  isViewsCountEnabled: getIsViewsCountEnabled(state, host.style),
  isFooterPostsEnabled: getIsFooterPostsEnabled(state, host.style),
  redirectToCategory: categorySlug => actions.navigateWithinForum(`/${categorySlug}`),
  hasPP: hasPaidPlans(state),
  uniquePostTypesInAllCategories: getUniquePostTypesInAllCategories(state),
});

export default flowRight(
  connect(mapRuntimeToProps, [REDUCERS.CATEGORIES, REDUCERS.PAID_PLANS]),
  withDeviceType,
)(CategoriesPage);
