import React from 'react';
import PropTypes from 'prop-types';
import { closeModalWithoutCallback } from './services/close-modal-without-callback';

const ModalMinimizable = ({
  className,
  onClose,
  onStateToggle,
  isMinimized,
  minComponent,
  maxComponent,
  dataHook,
  keepMounted = false,
}) => {
  const componentProps = {
    onClose: () => closeModalWithoutCallback(onClose),
    onStateToggle,
    isMinimized,
  };
  const MaximizedComponent = React.cloneElement(maxComponent, {
    ...componentProps,
    key: 'maximized',
  });
  const MinimizedComponent = React.cloneElement(minComponent, {
    ...componentProps,
    key: 'minimized',
  });

  return (
    <div className={className} data-hook={dataHook}>
      <div style={{ display: isMinimized && keepMounted ? 'none' : null }}>
        {keepMounted ? MaximizedComponent : isMinimized && MaximizedComponent}
      </div>
      <div style={{ display: !isMinimized & keepMounted ? 'none' : null }}>
        {keepMounted ? MinimizedComponent : !isMinimized && MaximizedComponent}
      </div>
    </div>
  );
};

ModalMinimizable.propTypes = {
  minComponent: PropTypes.node.isRequired,
  maxComponent: PropTypes.node.isRequired,
  isMinimized: PropTypes.bool.isRequired,
  onStateToggle: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  className: PropTypes.string,
  dataHook: PropTypes.string,
  keepMounted: PropTypes.bool,
};

export default ModalMinimizable;
