import { css } from 'emotion';
import { TinyColor } from '@ctrl/tinycolor';
import { getSiteColor } from '@wix/communities-forum-client-commons/dist/src/services/colors';
import { BUTTON_COLOR } from '@wix/communities-forum-client-commons/dist/src/constants/wix-params';

export const buildCssOverride = style => {
  const mentionColor = new TinyColor(
    getSiteColor(style, {
      wixParam: BUTTON_COLOR,
      presetFallback: 'color-8',
      colorFallback: '#00000',
    }),
  )
    .setAlpha(0.2)
    .toRgbString();
  return {
    mentionSuggestions: css`
      z-index: 1000 !important;
      box-shadow: 0 2px 20px rgba(0, 0, 0, 0.1) !important;
    `,
    mention: css`
      background: ${mentionColor} !important;
    `,
  };
};
