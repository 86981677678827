import React, { useEffect } from 'react';
import classNames from 'classnames';
import styles from '../post-form/post-form-desktop.scss';
import { Text } from 'wix-ui-tpa/Text';
import { IconButton } from 'wix-ui-tpa/IconButton';
import RichContentViewer from '../rich-content-viewer/rich-content-viewer';
import PostContent from '../post-content/post-content';
import { flowRight } from 'lodash';
import { ReactComponent as Close } from 'wix-ui-tpa/dist/src/assets/icons/Close.svg';
import withCardBorderWidth from '../../hoc/with-card-border-width';
import withTranslate from '../../../common/components/with-translate/with-translate';
import { connect } from '../../../common/components/runtime-context';
import {
  CATEGORY_GUIDELINES,
  getDisplayGuidelinesTypeFromStore,
  getGuidelinesContent,
} from '../../selectors/guidelines-selectors';
import PropTypes from 'prop-types';
import { classes } from './post-guidelines-sidebar.st.css';

const PostGuidelinesSidebar = ({
  t,
  type,
  borderWidth,
  content,
  onClose,
  onShown,
  hasCategoryChanged,
  isEdit,
  categoryId,
}) => {
  useEffect(() => {
    onShown({
      type,
      origin: hasCategoryChanged ? 'on_category_change' : isEdit ? 'edit' : 'create_post',
      categoryId,
    });
  }, [categoryId, hasCategoryChanged, isEdit, type, onShown]);

  return (
    <div
      data-hook="guidelines-section"
      className={classNames(styles.guidelinesArea, 'forum-card-border-color')}
      style={{ borderWidth }}
    >
      <div className={styles.guidelinesAreaHeader}>
        <Text className={classes.title}>
          {t(
            type === CATEGORY_GUIDELINES
              ? 'guidelines.title.category-guidelines'
              : 'guidelines.title.forum-guidelines',
          )}
        </Text>
        <IconButton onClick={onClose} icon={<Close />} />
      </div>

      <div className={styles.guidelinesAreaContent}>
        <RichContentViewer
          initialState={content}
          readOnly
          origin="post"
          type={PostContent.POST_PAGE}
          contentId={`guidelines:${categoryId}`}
        />
      </div>
    </div>
  );
};

PostGuidelinesSidebar.propTypes = {
  t: PropTypes.func,
  type: PropTypes.string,
  categoryId: PropTypes.string,
  borderWidth: PropTypes.number,
  content: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
  onShown: PropTypes.func.isRequired,
  hasCategoryChanged: PropTypes.bool,
  isEdit: PropTypes.bool,
};

const mapRuntimeToProps = (state, props, actions) => ({
  content: getGuidelinesContent(state)(props.categoryId),
  type: getDisplayGuidelinesTypeFromStore(state, props.categoryId),
  onShown: actions.userEventsGuidelinesShown,
});

export default flowRight(
  connect(mapRuntimeToProps),
  withTranslate,
  withCardBorderWidth,
)(PostGuidelinesSidebar);
