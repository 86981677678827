import React from 'react';
import ModalA11yContext from './modal-a11y-context';
import getDisplayName from 'react-display-name';
import hoistNonReactStatics from 'hoist-non-react-statics';

export const withModalA11yProps = WrappedComponent => {
  class ModalA11yConnector extends React.Component {
    static contextType = ModalA11yContext;
    static displayName = `connect(${getDisplayName(WrappedComponent)})`;

    render() {
      const { titleId } = this.context;

      return <WrappedComponent {...this.props} titleId={titleId} />;
    }
  }
  hoistNonReactStatics(ModalA11yConnector, WrappedComponent);

  return ModalA11yConnector;
};
