import { flowRight, get } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from '../../../common/components/runtime-context';
import ProtectedButton from '../../containers/protected-button';
import LikeButton from '../like-button';
import ReplyButton from '../reply-button';
import PostWithSidebarSocialActions from '../post-social-actions';
import CommentingDisabled from '../commenting-disabled';
import { LIKE_POST } from '../../constants/interactions';
import { QUESTION } from '@wix/communities-forum-client-commons/dist/src/constants/post-types';
import { getIsSocialSharingLinksEnabled } from '../../selectors/app-settings-selectors';
import { isEditor, isPreview } from '../../../common/store/basic-params/basic-params-selectors';
import { scrollToPostCommentForm } from '../../services/scroll-to-post-comment-form';
import withPermissions from '../../hoc/with-permissions';
import withTranslate from '../../../common/components/with-translate/with-translate';
import withAuth from '../../hoc/with-auth';
import styles from './post-main-actions.scss';
import { LockSlimIcon } from '../icons/lock-slim-icon';
import { WhoLikedPopover } from '../who-liked';
import { getPostLikeCount } from '../../selectors/post-selectors';
import withExperiment from '../../hoc/with-experiment';
import { EXPERIMENT_WIX_COMMENTS } from '@wix/communities-forum-client-commons/dist/src/constants/experiments';
import classNames from 'classnames';

const PostMainActions = ({
  can,
  category,
  post,
  onLikeClick,
  onReplyClick,
  t,
  forPublicUser,
  isSocialSharingLinksEnabled,
  shouldShowShareButton,
  isMobile,
  likeCount,
  isWixCommentsEnabled,
}) => {
  const canShare =
    can('read', 'post', post) && shouldShowShareButton && isSocialSharingLinksEnabled;
  const isQuestion = post.postType === QUESTION;
  const isCommentsDisabled = get(post, 'isCommentsDisabled', false);

  const renderLikeButton = () => (
    <span className={styles.buttonWrapper}>
      <ProtectedButton
        className={styles.button}
        onClick={forPublicUser(onLikeClick)}
        actionDetails={{ action: LIKE_POST, args: [post._id] }}
      >
        <LikeButton
          onClick={onLikeClick}
          isLiked={post.isLiked}
          likeCount={likeCount}
          isDisabled={post.isLikeInProgress}
        />
      </ProtectedButton>
      <WhoLikedPopover
        type="post"
        postId={post._id}
        totalItemCount={likeCount}
        initialItems={post.likes}
      />
    </span>
  );

  const renderCommentLink = () =>
    isWixCommentsEnabled ? null : (
      <React.Fragment>
        {!isCommentsDisabled ? (
          <span className={styles.reply}>
            <ReplyButton
              userName={get(post, 'owner.name')}
              onClick={onReplyClick}
              type={isQuestion ? 'answer' : 'comment'}
            />
          </span>
        ) : isMobile ? (
          <span className={styles.reply}>
            <CommentingDisabled
              message={t(isQuestion ? 'post-page.answering-off' : 'post-page.commenting-off')}
              isInPostMainAction={isMobile}
            />
          </span>
        ) : (
          <div className={styles.commentsDisabled}>
            <LockSlimIcon className="forum-icon-fill" data-hook="lock-icon" />
            <span className={styles.disabledText}>
              {t(isQuestion ? 'post-page.answering-off' : 'post-page.commenting-off')}
            </span>
          </div>
        )}
      </React.Fragment>
    );

  const renderShareButton = () => {
    return canShare ? (
      <PostWithSidebarSocialActions
        post={post}
        category={category}
        className={classNames(styles.socialActions, isWixCommentsEnabled && styles.extraSpacing)}
        path={`/${category.slug}/${post.slug}`}
      />
    ) : null;
  };

  return (
    <div className={styles.container}>
      <div className={styles.buttonContainer}>
        <div className={styles.leftSide}>
          {isMobile ? renderLikeButton() : renderCommentLink()}
          {isMobile ? renderCommentLink() : renderLikeButton()}
          {isWixCommentsEnabled && renderShareButton()}
        </div>
        {!isWixCommentsEnabled && renderShareButton()}
      </div>
    </div>
  );
};

PostMainActions.propTypes = {
  category: PropTypes.object.isRequired,
  post: PropTypes.object.isRequired,
  onReplyClick: PropTypes.func.isRequired,
  onLikeClick: PropTypes.func.isRequired,
  can: PropTypes.func,
  t: PropTypes.func,
  forPublicUser: PropTypes.func,
  isSocialSharingLinksEnabled: PropTypes.bool,
  shouldShowShareButton: PropTypes.bool.isRequired,
  isMobile: PropTypes.bool,
};

const mapRuntimeToProps = (state, ownProps, actions, host) => ({
  likeCount: getPostLikeCount(state, ownProps.post._id),
  isSocialSharingLinksEnabled: getIsSocialSharingLinksEnabled(state, host.style),
  shouldShowShareButton: !isEditor(state) && !isPreview(state),
  onReplyClick: ownProps.onReplyClick || scrollToPostCommentForm,
  onLikeClick: () => {
    actions.userEventsClickLike({
      type: 'post',
      _id: ownProps.post._id,
      isLiked: ownProps.post.isLiked,
    });
    actions.interactionStarted(LIKE_POST);
    return actions.incrementPostLikeCount(ownProps.post._id);
  },
});

export default flowRight(
  connect(mapRuntimeToProps),
  withPermissions,
  withTranslate,
  withAuth,
  withExperiment({
    isWixCommentsEnabled: EXPERIMENT_WIX_COMMENTS,
  }),
)(PostMainActions);
