import React, { Component } from 'react';
import { connect } from '../../common/components/runtime-context';
import { isString, memoize, pick, flowRight, noop, identity, mapValues, mapKeys } from 'lodash';
import {
  createHashtagHref,
  createHashtagPath,
  HTML_IFRAME_SRC,
  MAX_PINTEREST_IMAGE_SIZE,
} from '../components/rich-content-editor/utils';
import {
  RCE_FILE_DOWNLOAD_WILL_BEGIN_SHORTLY,
  RCE_FILE_SIZE_LIMIT_REACHED,
  RCE_FILE_STORAGE_QUOTA_REACHED,
  RCE_FILE_TOO_BIG,
  RCE_FILE_UPLOAD_FAILURE,
  RCE_FILE_UPLOAD_LIMITED,
} from '../components/messages/message-types';
import { supportedMimeTypes } from '../../common/services/wix-media/supported-mime-types';
import { isIos } from '../../common/services/detect-platform';
import {
  customStyleFn,
  getTextColorSchema,
  styleSelectionPredicate,
  viewerCustomStyleFn,
} from '../components/rich-content-editor/text-color-utils';
import { pluginLink as pluginLinkViewer } from 'wix-rich-content-plugin-link/dist/module.viewer.cjs';
import { pluginHashtag as pluginHashtagViewer } from 'wix-rich-content-plugin-hashtag/dist/module.viewer.cjs';
import { pluginImage as pluginImageViewer } from 'wix-rich-content-plugin-image/dist/module.viewer.cjs';
import { pluginVideo as pluginVideoViewer } from 'wix-rich-content-plugin-video/dist/module.viewer.cjs';
import { pluginDivider as pluginDividerViewer } from 'wix-rich-content-plugin-divider/dist/module.viewer.cjs';
import { pluginHtml as pluginHtmlViewer } from 'wix-rich-content-plugin-html/dist/module.viewer.cjs';
import { pluginMentions as pluginMentionsViewer } from 'wix-rich-content-plugin-mentions/dist/module.viewer.cjs';
import { pluginCodeBlock as pluginCodeBlockViewer } from 'wix-rich-content-plugin-code-block/dist/module.viewer.cjs';
import {
  pluginTextColor as pluginTextColorViewer,
  pluginTextHighlight as pluginTextHighlightViewer,
} from 'wix-rich-content-plugin-text-color/dist/module.viewer.cjs';
import { pluginGiphy as pluginGiphyViewer } from 'wix-rich-content-plugin-giphy/dist/module.viewer.cjs';
import { pluginEmoji as pluginEmojiViewer } from 'wix-rich-content-plugin-emoji/dist/module.viewer.cjs';
import { pluginFileUpload as pluginFileUploadViewer } from 'wix-rich-content-plugin-file-upload/dist/module.viewer.cjs';
import { pluginLinkPreview as pluginLinkPreviewViewer } from 'wix-rich-content-plugin-link-preview/dist/module.viewer.cjs';
import { pluginVerticalEmbed as pluginVerticalEmbedViewer } from 'wix-rich-content-plugin-vertical-embed/dist/module.viewer.cjs';
import { getIsStorageLimitReached } from '../selectors/forum-data-selectors';
import { uploadFileToWixMedia as uploadFileToWixMediaAction } from '../../common/services/wix-media/wix-media-file-upload-service';
import { uploadVideoToWixMedia as uploadVideoToWixMediaAction } from '../../common/services/wix-media/wix-media-video-upload-service';
import { createSiteMembersFetchServiceDebounced } from '@wix/communities-forum-client-commons/dist/src/services/mentions-services';
import URI from '@wix/communities-forum-client-commons/dist/src/services/urijs';
import {
  FILE_UPLOAD_SIZE_LIMIT_MB,
  VIDEO_UPLOAD_SIZE_LIMIT_MB,
} from '@wix/communities-forum-client-commons/dist/src/constants/media-limits';
import withTranslate from '../../common/components/with-translate/with-translate';
import {
  getEnabledVerticalEmbedPlugins,
  getIsUploadLimiterEnabled,
  getUploadSizeLimit,
  getEnabledRcePlugins,
  getIsSocialSharingLinksEnabled,
} from '../selectors/app-settings-selectors';
import { getUploadedRegistry } from '../selectors/uploaded-registry-selectors';
import {
  getApiBaseUrl,
  getInstance,
  getIsMobile,
  getLanguage,
  getRicosBiParams,
} from '../../common/store/basic-params/basic-params-selectors';
import getImageUrl from '@wix/communities-forum-client-commons/dist/src/services/get-image-url';
import { PLUGINS } from '@wix/communities-forum-client-commons/dist/src/constants/plugins-constants';
import { getLocation, getSectionUrl } from '../../common/store/location/location-selectors';
import { wrapUploadImageAction } from '../actions/image-upload/upload-wrapper';
import { RCE_FAILED_UPLOAD_ID, VISITOR_UPLOADS_FOLDER_ID } from '../constants/upload';
import { isWithinSizeLimits } from '@wix/communities-forum-client-commons/dist/src/services/wix-media/is-within-size-limits';
import { DEFAULT_PLUGINS } from '../components/rich-content-editor/constants';
import { isMemberAreaInstalled } from '../../common/store/communities-context/communities-context-selectors';
import withDeviceType from './with-device-type';
import { pluginTypeMap } from '../containers/wix-comments/plugins';

const MOCK_FAILED_VIDEO_UPLOAD_DATA = { data: { pathname: RCE_FAILED_UPLOAD_ID } };
const MOCK_FAILED_FILE_UPLOAD_DATA = { data: { id: RCE_FAILED_UPLOAD_ID } };

// https://wix.slack.com/archives/CAKBA7TDH/p1601553610052000
const memoizeFn = memoize.Cache === WeakMap ? identity : memoize;

export const withRcePluginsConfig = Comp => {
  class WithEditorPluginConfigs extends Component {
    state = {
      userColors: [],
    };

    getImagePluginConfig = isViewer => {
      const { showPin, pageUrl, showAdvancedImageSettings, isMobile } = this.props;
      const config = {
        imageProps: showPin
          ? src => ({
              'data-pin-url': pageUrl,
              'data-pin-media': isString(src)
                ? src
                : getImageUrl(src, MAX_PINTEREST_IMAGE_SIZE, MAX_PINTEREST_IMAGE_SIZE),
            })
          : { 'data-pin-nopin': true },
      };

      if (isViewer) {
        config.disableExpand = isMobile;
      } else {
        config.toolbar = {
          hidden: ['sizeFullWidth', ...(showAdvancedImageSettings ? [] : ['link', 'settings'])],
        };
      }

      return config;
    };

    getVideoPluginConfig = () => {
      const {
        isUploadLimiterEnabled,
        uploadSizeLimit,
        isOwner,
        userEventsHitUploadSizeLimit,
        showMessage,
        uploadVideoToWixMedia,
        waitForSuccessfulResponsePromisified,
        t,
      } = this.props;
      const config = {
        toolbar: {
          hidden: [],
        },
        getVideoUrl: src => `https://video.wixstatic.com/${src.pathname}`,
        enableCustomUploadOnMobile: true,
      };
      config.handleFileUpload = (file, updateEntity) => {
        const uploadLimit = isUploadLimiterEnabled ? uploadSizeLimit : VIDEO_UPLOAD_SIZE_LIMIT_MB;
        if (!isWithinSizeLimits(file, uploadLimit) && !isOwner) {
          if (isUploadLimiterEnabled) {
            // rce bug, updateEntity doesn't work here without setTimeout
            return setTimeout(() => {
              updateEntity(MOCK_FAILED_VIDEO_UPLOAD_DATA);

              updateEntity({
                error: {
                  msg: t('rich-content-editor.upload-size-limit', { amount: uploadSizeLimit }),
                },
              });
              userEventsHitUploadSizeLimit({
                limit: uploadSizeLimit,
                fileSize: file.size,
                fileType: 'video',
                type: origin,
              });
            }, 0);
          }
          return showMessage(RCE_FILE_TOO_BIG);
        }

        const folderId = isOwner ? null : VISITOR_UPLOADS_FOLDER_ID;
        uploadVideoToWixMedia(file, folderId)
          .then(({ promise }) => promise)
          .then(uploadData => {
            waitForSuccessfulResponsePromisified(
              `https://video.wixstatic.com/${uploadData.file_output.video[0].url}`,
            ).then(() => {
              const data = {
                pathname: uploadData.file_output.video[0].url,
                thumbnail: {
                  pathname: uploadData.file_output.image[0].url,
                  width: uploadData.file_output.image[0].width,
                  height: uploadData.file_output.image[0].height,
                },
              };

              updateEntity({ data });
            });
          })
          .catch(e => {
            const getErrorMessage = e => {
              switch (e) {
                case 406:
                  return 'rich-content-editor.site-storage-limited';
                case 429:
                  return 'rich-content-editor.video-upload-limited';
                default:
                  return 'rich-content-editor.video-upload-failure';
              }
            };
            const message = getErrorMessage(e);

            if (e === 406) {
              updateEntity(MOCK_FAILED_VIDEO_UPLOAD_DATA);
            }

            updateEntity({
              error: { msg: t(message) },
            });
          });
      };
      return config;
    };

    getFileUploadPluginConfig = () => {
      const {
        isUploadLimiterEnabled,
        uploadSizeLimit,
        isStorageLimitReached,
        showMessage,
        isOwner,
        userEventsHitUploadSizeLimit,
        uploadFileToWixMedia,
        fileUploaded,
      } = this.props;
      return {
        accept: supportedMimeTypes.join(','),
        onFileSelected: (file, updateEntity) => {
          const uploadLimit = isUploadLimiterEnabled ? uploadSizeLimit : FILE_UPLOAD_SIZE_LIMIT_MB;

          if (isStorageLimitReached) {
            updateEntity(MOCK_FAILED_FILE_UPLOAD_DATA);
            return showMessage(RCE_FILE_STORAGE_QUOTA_REACHED);
          }

          if (!isWithinSizeLimits(file, uploadLimit) && !isOwner) {
            if (isUploadLimiterEnabled) {
              userEventsHitUploadSizeLimit({
                limit: uploadSizeLimit,
                fileSize: file.size,
                fileType: 'file',
                type: origin,
              });
              updateEntity(MOCK_FAILED_FILE_UPLOAD_DATA);
              return showMessage(RCE_FILE_SIZE_LIMIT_REACHED, { uploadSizeLimit });
            }
            return showMessage(RCE_FILE_TOO_BIG);
          }
          const name = file.name;
          const filenameParts = name.split('.');
          const type = name.includes('.') ? filenameParts[filenameParts.length - 1] : '';

          uploadFileToWixMedia(file)
            .then(uploadData => {
              const data = {
                name,
                type,
                mimeType: uploadData.mimeType,
                path: uploadData.path,
                size: uploadData.size,
                id: uploadData.id,
              };

              updateEntity({ data });
              fileUploaded({
                fileId: uploadData.id,
                fileExtension: type,
                isSuccessful: true,
                mimeType: uploadData.mimeType,
                size: uploadData.size,
                origin,
              });
            })
            .catch(e => {
              e === 429 && showMessage(RCE_FILE_UPLOAD_LIMITED);
              e === 400 && showMessage(RCE_FILE_UPLOAD_FAILURE);
              fileUploaded({
                fileId: null,
                fileExtension: type,
                isSuccessful: false,
                mimeType: file.type,
                size: file.size,
                origin,
              });
            });
        },
      };
    };

    getFileUploadPluginViewerConfig = () => {
      const { showMessage, requestFileDownloadUrlPromisified, fileDownloadRequest } = this.props;
      return {
        resolveFileUrl: imgEntityData => {
          isIos() && showMessage(RCE_FILE_DOWNLOAD_WILL_BEGIN_SHORTLY);
          const getBiEventData = isSuccessful => ({
            fileId: imgEntityData.id,
            fileExtension: imgEntityData.type,
            mimeType: imgEntityData.mimeType,
            size: imgEntityData.size,
            isSuccessful,
            origin,
          });

          return requestFileDownloadUrlPromisified(imgEntityData.path)
            .then(data => {
              fileDownloadRequest(getBiEventData(true));
              return data.downloadUrl;
            })
            .catch(() => fileDownloadRequest(getBiEventData(false)));
        },
      };
    };

    getColorPluginsConfig = isHighlight => {
      const colorScheme = getTextColorSchema(this.props.style);
      return {
        colorScheme,
        styleSelectionPredicate: styleSelectionPredicate(colorScheme),
        customStyleFn: customStyleFn(viewerCustomStyleFn(colorScheme, isHighlight)),
        getUserColors: () => this.state.userColors,
        onColorAdded: c => this.setState({ userColors: this.state.userColors.concat(c) }),
      };
    };

    getColorPluginsViewerConfig = isHighlight => {
      const colorScheme = getTextColorSchema(this.props.style);
      return {
        customStyleFn: viewerCustomStyleFn(colorScheme, isHighlight),
        styleSelectionPredicate: styleSelectionPredicate(colorScheme),
      };
    };

    onHashTagClick = event => {
      event.preventDefault();
      event.stopPropagation();
      const pathname = createHashtagPath(event.target.innerText);
      this.props.navigateWithinForum(pathname);
    };

    filterEnabledPlugins = plugins =>
      pick(plugins, [...DEFAULT_PLUGINS, ...this.props.enabledPlugins]);

    getPluginsConfig = memoizeFn(() => {
      const {
        enabledVerticalEmbedPlugins,
        instance,
        apiBaseUrl,
        sectionUrl,
        isMembersAreaInstalled,
        navigateToProfile,
      } = this.props;
      const plugins = {
        [PLUGINS.IMAGE]: {
          viewerModule: pluginImageViewer,
          config: this.getImagePluginConfig(),
          viewerConfig: this.getImagePluginConfig(true),
        },
        [PLUGINS.VIDEO]: {
          viewerModule: pluginVideoViewer,
          config: this.getVideoPluginConfig(),
          viewerConfig: {
            // Function is invoked when rendering video which has relative URL.
            // You should take the pathname and form a full URL.
            getVideoUrl: src => `https://video.wixstatic.com/${src.pathname}`,
          },
        },
        [PLUGINS.FILE_UPLOAD]: {
          viewerModule: pluginFileUploadViewer,
          config: this.getFileUploadPluginConfig(),
          viewerConfig: this.getFileUploadPluginViewerConfig(),
        },
        [PLUGINS.DIVIDER]: {
          viewerModule: pluginDividerViewer,
        },
        [PLUGINS.HTML]: {
          viewerModule: pluginHtmlViewer,
          config: {
            htmlIframeSrc: HTML_IFRAME_SRC,
          },
        },
        [PLUGINS.CODE_BLOCK]: {
          viewerModule: pluginCodeBlockViewer,
        },
        [PLUGINS.GIPHY]: {
          viewerModule: pluginGiphyViewer,
          config: {
            giphySdkApiKey: '8LVphUXpyDK77BY7BhYMXgFU6TwhSKkA',
            componentDataDefaults: { config: { size: 'small', alignment: 'center' } },
            insertToolbars: ['SIDE', 'FOOTER'],
          },
        },
        [PLUGINS.EMOJI]: {
          viewerModule: pluginEmojiViewer,
        },
        [PLUGINS.VERTICAL_EMBED]: {
          viewerModule: pluginVerticalEmbedViewer,
          config: {
            exposeEmbedButtons: enabledVerticalEmbedPlugins,
          },
        },
        [PLUGINS.LINK]: {
          viewerModule: pluginLinkViewer,
        },
        [PLUGINS.LINK_PREVIEW]: {
          viewerModule: pluginLinkPreviewViewer,
        },
        [PLUGINS.TEXT_COLOR]: {
          viewerModule: pluginTextColorViewer,
          config: this.getColorPluginsConfig(),
          viewerConfig: this.getColorPluginsViewerConfig(),
        },
        [PLUGINS.TEXT_HIGHLIGHT]: {
          viewerModule: pluginTextHighlightViewer,
          config: this.getColorPluginsConfig(true),
          viewerConfig: this.getColorPluginsViewerConfig(true),
        },
        [PLUGINS.HASHTAG]: {
          viewerModule: pluginHashtagViewer,
          config: {
            createHref: () => '',
            onClick: noop,
          },
          viewerConfig: {
            onClick: this.onHashTagClick,
            createHref: createHashtagHref(sectionUrl),
          },
        },
        [PLUGINS.MENTIONS]: {
          viewerModule: pluginMentionsViewer,
          config: {
            getMentions: createSiteMembersFetchServiceDebounced({ instance, apiBaseUrl }),
            entryHeight: 30,
            visibleItemsBeforeOverflow: 5,
          },
          viewerConfig: {
            onMentionClick: isMembersAreaInstalled
              ? mention => navigateToProfile({ memberId: mention.id, memberSlug: mention.slug })
              : null,
            getMentionLink: noop,
          },
        },
      };
      return this.filterEnabledPlugins(plugins);
    });

    loadEditorPluginsAsync = memoizeFn(async () => {
      const { editorPluginsConfig } = await import(
        /* webpackChunkName: "ricos-editor-plugins-config" */ '../containers/wix-comments/editor-plugins-lazy'
      );
      const enabledConfigs = this.filterEnabledPlugins(editorPluginsConfig);
      const pluginsConfig = this.getPluginsConfig();
      const editorConfig = mapValues(enabledConfigs, (value, key) => ({
        ...value,
        config: pluginsConfig[key].config,
      }));
      return mapKeys(editorConfig, (val, key) => pluginTypeMap[key]);
    });

    render() {
      return (
        <Comp
          {...this.props.originalProps}
          editorPluginsConfig={this.getPluginsConfig()}
          loadEditorPluginsAsync={this.loadEditorPluginsAsync}
        />
      );
    }
  }

  const mapRuntimeToProps = (state, ownProps, actions, host) => ({
    style: host.style,
    locale: getLanguage(state),
    pageUrl: new URI(getLocation(state).url).search('').toString(),
    instance: getInstance(state),
    sectionUrl: getSectionUrl(state),
    enabledPlugins: getEnabledRcePlugins(state, host.style),
    showPin: getIsSocialSharingLinksEnabled(state, host.style),
    uploadedRegistry: getUploadedRegistry(state),
    removeItemFromUploadedRegistry: actions.removeItemFromUploadedRegistry,
    uploadImage: wrapUploadImageAction(actions.uploadImageRCEPromisified, getIsMobile(state)),
    fetchOembed: actions.fetchOembed,
    fileUploaded: actions.fileUploaded,
    showMessage: actions.showMessage,
    waitForSuccessfulResponsePromisified: actions.waitForSuccessfulResponsePromisified,
    uploadFileToWixMedia: uploadFileToWixMediaAction(actions.requestCredentialsV2Promisified),
    uploadVideoToWixMedia: uploadVideoToWixMediaAction(actions.requestCredentialsPromisified),
    apiBaseUrl: getApiBaseUrl(state),
    isUploadLimiterEnabled: getIsUploadLimiterEnabled(state, host.style),
    uploadSizeLimit: getUploadSizeLimit(state, host.style),
    isStorageLimitReached: getIsStorageLimitReached(state),
    userEventsHitUploadSizeLimit: actions.userEventsHitUploadSizeLimit,
    ricosBiParams: getRicosBiParams(state),
    experiments: state.experiments,
    enabledVerticalEmbedPlugins: getEnabledVerticalEmbedPlugins(host.style),
    requestFileDownloadUrlPromisified: actions.requestFileDownloadUrlPromisified,
    fileDownloadRequest: actions.fileDownloadRequest,
    originalProps: ownProps,
    navigateWithinForum: actions.navigateWithinForum,
    isMembersAreaInstalled: isMemberAreaInstalled(state),
    navigateToProfile: actions.navigateToProfile,
  });

  return flowRight(
    connect(mapRuntimeToProps),
    withTranslate,
    withDeviceType,
  )(WithEditorPluginConfigs);
};
