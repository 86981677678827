import { flowRight, get } from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { connect } from '../../../common/components/runtime-context';
import { scrollToPostCommentForm } from '../../services/scroll-to-post-comment-form';
import { getCategories, getAllCategories } from '../../../common/selectors/categories-selectors';
import Button from '../button';
import { SUBSCRIBE_SUCCESS } from '../../actions/subscribe';
import { UNSUBSCRIBE_SUCCESS } from '../../actions/unsubscribe';
import { FOLLOW_POST } from '../../constants/interactions';
import { QUESTION } from '@wix/communities-forum-client-commons/dist/src/constants/post-types';
import { SIDEBAR } from '../../constants/bi-locations';
import ensureAuth from '../../hoc/ensure-auth';
import withFontClassName from '../../hoc/with-font-class-name';
import withTranslate from '../../../common/components/with-translate/with-translate';
import { getRelatedAndNewPosts } from '../../selectors/post-selectors';
import PostPageSidebarStats from './post-page-sidebar-stats';
import PostPageSidebarCategoryList from './post-page-sidebar-category-list';
import PostPageSidebarNewPosts from './post-page-sidebar-new-posts';
import styles from './post-page-sidebar.scss';
import StickyContainer from '../../containers/sticky-container/sticky-container';
import { LockSlimIcon } from '../icons/lock-slim-icon';
import { NotificationFollowingIcon } from '../icons/notification-following-icon';
import { NotificationIcon } from '../icons/notification-icon';
import { getIsViewsCountEnabled } from '../../selectors/app-settings-selectors';
import { REDUCERS } from '../../../common/components/runtime-context/reducers';

const ActionButtonRaw = React.memo(({ className, text, isProtected, ...props }) => {
  const Component = isProtected ? ensureAuth(Button) : Button;
  return (
    <Component className={classNames(styles.button, className)} {...props} isSmall>
      {text}
    </Component>
  );
});

ActionButtonRaw.propTypes = {
  onClick: PropTypes.func,
  text: PropTypes.string,
  disabled: PropTypes.bool,
  contentFontClassName: PropTypes.string,
  isSecondary: PropTypes.bool,
  isProtected: PropTypes.bool,
};

const ActionButton = withFontClassName(ActionButtonRaw);

class PostPageSidebar extends React.PureComponent {
  constructor(props) {
    super(props);
    this.containerRef = React.createRef();
  }

  handleSubscription = () => {
    const {
      post: { _id, isSubscribed },
      subscribe,
      unsubscribe,
    } = this.props;

    if (isSubscribed) {
      unsubscribe(_id);
    } else {
      subscribe(_id);
    }
  };

  render() {
    const { post, categories, allCategories, posts, isViewsCountEnabled, t } = this.props;
    const hasMultipleCategories = allCategories.length > 1;
    const isCommentsDisabled = get(post, 'isCommentsDisabled', false);
    const isQuestion = post.postType === QUESTION;
    const followTextKey = post.isSubscribed ? 'post-actions.unfollow' : 'post-actions.follow';

    const commentTextKey = isCommentsDisabled
      ? isQuestion
        ? 'post-page.answering-off'
        : 'post-page.commenting-off'
      : isQuestion
      ? 'comment.answer'
      : 'comment.comment';

    const commentButtonContent = (
      <span className={classNames(styles.commentButtonContent)}>
        {isCommentsDisabled && (
          <LockSlimIcon
            className={classNames('button-primary-icon-fill', styles.commentButtonIcon)}
          />
        )}
        {t(commentTextKey)}
      </span>
    );

    return (
      <StickyContainer defaultOffset={20}>
        <div className={classNames(styles.container)} ref={this.containerRef}>
          <section>
            <ActionButton
              onClick={scrollToPostCommentForm}
              text={commentButtonContent}
              disabled={isCommentsDisabled}
              className={styles.commentButton}
              isProtected={false}
            />
            <ActionButton
              onClick={this.handleSubscription}
              text={
                <div className={styles.followButtonContent}>
                  {post.isSubscribed ? (
                    <NotificationFollowingIcon className="button-fill" />
                  ) : (
                    <NotificationIcon className="button-fill" />
                  )}
                  <span>{t(followTextKey)}</span>
                </div>
              }
              actionDetails={{ action: FOLLOW_POST, args: [post._id] }}
              isSecondary={true}
              className={styles.followButton}
              isProtected
            />
          </section>
          <PostPageSidebarStats
            viewCount={post.viewCount}
            totalComments={post.totalComments}
            totalReplies={post.totalReplies}
            postType={post.postType}
            isViewsCountEnabled={isViewsCountEnabled}
            postId={post._id}
          />
          <PostPageSidebarNewPosts categoryId={post.categoryId} posts={posts} showRelatedPosts />
          {hasMultipleCategories && (
            <PostPageSidebarCategoryList categories={categories} allCategories={allCategories} />
          )}
        </div>
      </StickyContainer>
    );
  }
}

PostPageSidebar.propTypes = {
  t: PropTypes.func,
  unsubscribe: PropTypes.func,
  subscribe: PropTypes.func,
  post: PropTypes.object,
  commentCount: PropTypes.number.isRequired,
  viewCount: PropTypes.number.isRequired,
  isViewsCountEnabled: PropTypes.bool,
};

const mapRuntimeToProps = (state, ownProps, actions, host) => {
  const posts = getRelatedAndNewPosts(state);
  return {
    posts,
    subscribe: (...args) => {
      actions.setBiLocation(SIDEBAR, SUBSCRIBE_SUCCESS);
      actions.subscribeToPost(...args);
    },
    unsubscribe: (...args) => {
      actions.setBiLocation(SIDEBAR, UNSUBSCRIBE_SUCCESS);
      actions.unsubscribeFromPost(...args);
    },
    categories: getCategories(state),
    allCategories: getAllCategories(state),
    isViewsCountEnabled: getIsViewsCountEnabled(state, host.style),
  };
};

export default flowRight(
  withTranslate,
  withFontClassName,
  connect(mapRuntimeToProps, [REDUCERS.CATEGORIES, REDUCERS.RELATED_POSTS, REDUCERS.FOOTER_POSTS]),
)(PostPageSidebar);
