import { map } from 'lodash';
import { PLUGINS } from '@wix/communities-forum-client-commons/dist/src/constants/plugins-constants';
import { pluginLink } from 'wix-rich-content-plugin-link/dist/module.viewer.cjs';
import { pluginHashtag } from 'wix-rich-content-plugin-hashtag/dist/module.viewer.cjs';
import { pluginImage } from 'wix-rich-content-plugin-image/dist/module.viewer.cjs';
import { pluginVideo } from 'wix-rich-content-plugin-video/dist/module.viewer.cjs';
import { pluginDivider } from 'wix-rich-content-plugin-divider/dist/module.viewer.cjs';
import { pluginHtml } from 'wix-rich-content-plugin-html/dist/module.viewer.cjs';
import { pluginMentions } from 'wix-rich-content-plugin-mentions/dist/module.viewer.cjs';
import { pluginCodeBlock } from 'wix-rich-content-plugin-code-block/dist/module.viewer.cjs';
import {
  pluginTextColor,
  pluginTextHighlight,
} from 'wix-rich-content-plugin-text-color/dist/module.viewer.cjs';
import { pluginGiphy } from 'wix-rich-content-plugin-giphy/dist/module.viewer.cjs';
import { pluginEmoji } from 'wix-rich-content-plugin-emoji/dist/module.viewer.cjs';
import { pluginFileUpload } from 'wix-rich-content-plugin-file-upload/dist/module.viewer.cjs';
import { pluginLinkPreview } from 'wix-rich-content-plugin-link-preview/dist/module.viewer.cjs';
import { pluginVerticalEmbed } from 'wix-rich-content-plugin-vertical-embed/dist/module.viewer.cjs';

const PLUGIN_MODULES = {
  [PLUGINS.IMAGE]: pluginImage,
  [PLUGINS.VIDEO]: pluginVideo,
  [PLUGINS.LINK]: pluginLink,
  [PLUGINS.HASHTAG]: pluginHashtag,
  [PLUGINS.DIVIDER]: pluginDivider,
  [PLUGINS.HTML]: pluginHtml,
  [PLUGINS.MENTION]: pluginMentions,
  [PLUGINS.CODE_BLOCK]: pluginCodeBlock,
  [PLUGINS.TEXT_COLOR]: pluginTextColor,
  [PLUGINS.TEXT_HIGHLIGHT]: pluginTextHighlight,
  [PLUGINS.GIPHY]: pluginGiphy,
  [PLUGINS.FILE_UPLOAD]: pluginFileUpload,
  [PLUGINS.EMOJI]: pluginEmoji,
  [PLUGINS.LINK_PREVIEW]: pluginLinkPreview,
  [PLUGINS.VERTICAL_EMBED]: pluginVerticalEmbed,
};

export const mapConfigToPlugins = config =>
  map(PLUGIN_MODULES, (plugin, key) => {
    const pluginConfig = config[key];
    return pluginConfig ? plugin(pluginConfig) : plugin();
  });
