import { PLUGINS } from '@wix/communities-forum-client-commons/dist/src/constants/plugins-constants';

export const pluginTypeMap = {
  [PLUGINS.LINK]: 'LINK',
  [PLUGINS.HASHTAG]: 'wix-draft-plugin-hashtag',
  [PLUGINS.MENTIONS]: 'mention',
  [PLUGINS.TEXT_COLOR]: 'wix-rich-content-text-color',
  [PLUGINS.LINK_PREVIEW]: 'wix-draft-plugin-link-preview',
  [PLUGINS.TEXT_HIGHLIGHT]: 'wix-rich-content-text-highlight',
  [PLUGINS.IMAGE]: 'wix-draft-plugin-image',
  [PLUGINS.VIDEO]: 'wix-draft-plugin-video',
  [PLUGINS.DIVIDER]: 'wix-draft-plugin-divider',
  [PLUGINS.HTML]: 'wix-draft-plugin-html',
  [PLUGINS.CODE_BLOCK]: 'code-block',
  [PLUGINS.GIPHY]: 'wix-draft-plugin-giphy',
  [PLUGINS.FILE_UPLOAD]: 'wix-draft-plugin-file-upload',
  [PLUGINS.EMOJI]: 'wix-draft-plugin-emoji',
  [PLUGINS.VERTICAL_EMBED]: 'wix-draft-plugin-vertical-embed',
};
