import { flowRight, isEmpty, get } from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import classNames from 'classnames';
import { connect } from '../../../common/components/runtime-context';
import { getCommentsPerPage } from '../../constants/pagination';
import { DISCUSSION } from '@wix/communities-forum-client-commons/dist/src/constants/post-types';
import AccountSuspended from '../../components/account-suspended';
import AnimatedLoader from '../../components/animated-loader';
import Button from '../../components/button';
import CommentingDisabled from '../../components/commenting-disabled';
import EmptyStates from '../../components/empty-states';
import Link from '../../components/link/internal-link';
import PostPageSidebar from '../../components/post-page-sidebar';
import Pagination from '../../components/pagination-comments';
import PostContent from '../../components/post-content';
import PostHeader from '../../components/post-header';
import PostPageLayout from '../../components/post-page-layout';
import PostPageStats from '../../components/post-page-stats';
import PostTitle from '../../components/post-title';
import { HorizontalSeparator } from '../../components/separator';
import PostCommentList from '../post-comment-list';
import PostMainActions from '../../components/post-main-actions';
import PostPageFooter from '../post-page-footer';
import FooterPosts from '../../components/footer-posts';
import { REDUCERS } from '../../../common/components/runtime-context/reducers';
import { getCategoryBySlug } from '../../../common/selectors/categories-selectors';
import { getCommentsByPostId } from '../../selectors/comment-selectors';
import { getIsPostLoaded, getIsCommentsLoaded } from '../../selectors/is-loaded-selectors';
import { getIsPostLoading, getIsCommentsLoading } from '../../selectors/is-loading-selectors';
import { getEntityCount } from '../../selectors/pagination-selectors';
import { getPostBySlug } from '../../selectors/post-selectors';
import withAuth from '../../hoc/with-auth';
import withCardBorderWidth from '../../hoc/with-card-border-width';
import withFontClassName from '../../hoc/with-font-class-name';
import withTranslate from '../../../common/components/with-translate/with-translate';
import { getRouteParams, getPreviousMatches } from '../../../common/router/router-selectors';
import { scrollToComment } from '../../services/scroll-to-comment';
import { getDeepLinkParams } from '../../services/get-deep-link-params';
import {
  ROUTE_CREATE_POST,
  ROUTE_CREATE_QUESTION,
  ROUTE_CATEGORY_CREATE_POST,
  ROUTE_CATEGORY_CREATE_QUESTION,
  ROUTE_POST_EDIT,
} from '@wix/communities-forum-client-commons/dist/src/constants/routes';
import { getIsFooterPostsEnabled } from '../../selectors/app-settings-selectors';
import { isMembersOnly } from '@wix/communities-forum-client-commons/dist/src/services/category-privacy-utils';
import { buildPageUrl } from '@wix/communities-forum-client-commons/dist/src/services/pagination';
import { isSSR } from '../../../common/store/basic-params/basic-params-selectors';
import styles from './post-page-desktop.scss';
import withExperiment from '../../hoc/with-experiment';
import { EXPERIMENT_WIX_COMMENTS } from '@wix/communities-forum-client-commons/dist/src/constants/experiments';
import PostPageWixComments from '../wix-comments/post-page-wix-comments';

export class PostPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      initialPage: props.page,
      currentPage: props.page,
      showCommentListLoader: false,
    };
    this.postContainerRef = React.createRef();
  }

  componentDidMount = () => {
    const { commentToFocusId, commentToFocusIdParentId, allComments } = this.props;
    if (commentToFocusId) {
      let pinTime;

      if (allComments.length >= 10) {
        pinTime = 2000;
      } else if (allComments.length >= 14) {
        pinTime = 2500;
      }
      scrollToComment(commentToFocusId, {
        isReply: commentToFocusIdParentId !== undefined,
        pinTime,
      });
    } else if (this.postContainerRef.current) {
      this.postContainerRef.current.focus({ preventScroll: true });
    }
  };

  componentDidUpdate(prevProps) {
    if (prevProps.commentToFocusId !== this.props.commentToFocusId) {
      scrollToComment(this.props.commentToFocusId);
    }
    if (prevProps.page !== this.props.page) {
      this.setState({ currentPage: this.props.page, showCommentListLoader: false });
    }
  }

  componentWillUnmount = () => {
    this.props.setIsLoaded('comments', this.props.params.postSlug, false);

    if (!isEmpty(this.props.category)) {
      this.props.emitClosePost(this.props.post._id);
    }

    this.props.clearComments();
  };

  renderPaginationTop = () => {
    const { entityCount, commentsPerPage } = this.props;
    return (
      <Pagination
        page={this.state.currentPage}
        entityCount={entityCount}
        showPerPage={commentsPerPage}
        onChange={this.handlePageChange}
        buildPageUrl={this.buildPageUrl}
        className={styles.pagination}
        isTop
      />
    );
  };

  renderPagination = () => {
    const { entityCount, isCommentsLoaded, commentsPerPage } = this.props;

    if (isCommentsLoaded && entityCount > commentsPerPage) {
      return (
        <Pagination
          page={this.state.currentPage}
          entityCount={entityCount}
          showPerPage={commentsPerPage}
          onChange={this.handlePageChange}
          buildPageUrl={this.buildPageUrl}
        />
      );
    }
  };

  buildPageUrl = (page = 1) => {
    const {
      params: { postSlug, categorySlug },
    } = this.props;
    return buildPageUrl(categorySlug, postSlug, page);
  };

  handlePageChange = ({ page, buttonType }) => {
    const {
      post,
      params: { categorySlug },
      navigateWithinForum,
      changePage,
    } = this.props;
    changePage({
      page,
      meta: {
        bi: {
          buttonType,
        },
      },
    });
    navigateWithinForum(buildPageUrl(categorySlug, post.slug, page));
  };

  loadMoreMobile = () => {
    const {
      post,
      params: { categorySlug },
      page,
      navigateWithinForum,
    } = this.props;
    navigateWithinForum(buildPageUrl(categorySlug, post.slug, page + 1));
  };

  renderFooter = () => {
    const { isBlocked, post, t, allComments, isCommentsLoading, isCommentsLoaded } = this.props;
    if (isBlocked || post.isCommentsDisabled) {
      const key = post.postType === DISCUSSION ? 'commenting' : 'answering';
      return (
        <div>
          <CommentingDisabled
            message={t(isBlocked ? `post-page.${key}-blocked` : `post-page.${key}-off`)}
          />
        </div>
      );
    }

    const showFooter = (!isCommentsLoading && isCommentsLoaded) || !isEmpty(allComments);
    if (!showFooter) {
      return null;
    }

    return (
      <div className={styles.footerContainer}>
        <PostPageFooter postId={post._id} postType={post.postType} />
      </div>
    );
  };

  renderPost = () => {
    const {
      category,
      post,
      isAnimated,
      borderWidth,
      titleFontClassName,
      page,
      allComments,
      postOwnerBadges,
      entityCount,
      commentsPerPage,
      isWixCommentsEnabled,
    } = this.props;
    const hasComments = !isEmpty(allComments);
    const hasPagination = entityCount > commentsPerPage;

    if (page > 1) {
      return null;
    }

    const postContainerClassName = classNames(
      styles.postContainer,
      {
        [styles.withComments]: hasComments,
        [styles.withPagination]: hasPagination,
        [styles.withWixComments]: isWixCommentsEnabled,
      },
      'forum-card-border-color',
      'forum-text-color',
    );

    return (
      <React.Fragment>
        <main
          ref={this.postContainerRef}
          tabIndex="-1"
          className={postContainerClassName}
          style={{ borderWidth }}
          aria-labelledby={`post-header-${post.slug}`}
        >
          <div className={styles.postContentWrapper} data-hook="post-page__post">
            {isWixCommentsEnabled ? (
              <PostHeader
                type={PostHeader.POST_PAGE_NEW}
                post={post}
                isAnimated={isAnimated}
                showDateBelowAvatar
                badge={postOwnerBadges}
                showEditedDate
                showMoreButton
              />
            ) : (
              <PostHeader
                type={PostHeader.POST_PAGE}
                post={post}
                isAnimated={isAnimated}
                showDateBelowAvatar={false}
                badge={postOwnerBadges}
                showEditedDate
                showUserInfoInline
                showMoreButton
              />
            )}
            <PostTitle
              className={titleFontClassName}
              type={PostTitle.POST_PAGE}
              title={post.title || ''}
              component="h1"
              id={`post-header-${post.slug}`}
            />
            <div className={styles.postContent}>
              <PostContent post={post} type={PostContent.POST_PAGE} />
            </div>
            <div className={styles.postStatsContainer}>
              <PostPageStats
                viewCount={post.viewCount}
                commentCount={post.totalComments - post.totalReplies}
                replyCount={post.totalReplies}
                isAnimated={isAnimated}
                postType={post.postType}
                postId={post._id}
              />
            </div>
            <PostMainActions category={category} post={post} isMobile={false} />
          </div>
        </main>
        {!isWixCommentsEnabled && hasComments && (
          <div
            className={classNames(styles.postCommentsSeparatorContainer, 'forum-card-border-color')}
          >
            <HorizontalSeparator className={styles.postCommentsSeparator} />
          </div>
        )}
      </React.Fragment>
    );
  };

  renderEmpty = () => {
    const { t } = this.props;
    return (
      <EmptyStates
        className={styles.emptyState}
        title={t('post-page.not-found-title')}
        content={t('post-page.not-fount-content')}
      >
        <Button component={Link} to="/">
          {t('post-page.back-to-forum')}
        </Button>
      </EmptyStates>
    );
  };

  render = () => {
    const {
      params: { postSlug },
      isBlocked,
      category,
      post,
      contentFontClassName,
      isLoading,
      isLoaded,
      isCommentsLoading,
      isCommentsLoaded,
      allComments,
      commentToFocusId,
      isFooterPostsEnabled,
      entityCount,
      isSSR,
      commentsPerPage,
      isWixCommentsEnabled,
    } = this.props;

    if (isEmpty(post) && !isLoading) {
      return this.renderEmpty();
    }

    if (!isEmpty(category) && isMembersOnly(category) && isBlocked) {
      return <AccountSuspended className={styles.emptyState} />;
    }

    const showCommentsLoader = isCommentsLoading && !isCommentsLoaded && isEmpty(allComments);
    const hasDesktopPagination = entityCount > commentsPerPage;
    return (
      <PostPageLayout
        className={classNames(
          styles.postPage,
          contentFontClassName,
          'forum-card-background-color',
          'forum-card-border-color',
        )}
      >
        {!isWixCommentsEnabled && this.renderPaginationTop()}
        <div
          className={classNames(styles.postWithSidebar, {
            [styles.withWixComments]: isWixCommentsEnabled,
          })}
        >
          <AnimatedLoader
            className={styles.innerContainer}
            isLoading={isLoading && !isLoaded && isEmpty(post)}
          >
            {hasDesktopPagination && (
              <HorizontalSeparator className={styles.postCommentsSeparator} />
            )}
            {this.renderPost()}
            <AnimatedLoader isLoading={showCommentsLoader} isAnimated>
              {isWixCommentsEnabled || isEmpty(allComments) ? null : (
                <div className={classNames(styles.commentsContainer, 'forum-card-border-color')}>
                  <PostCommentList
                    isDeepLinked={commentToFocusId}
                    page={this.props.page}
                    postSlug={postSlug}
                    isCommentsLoading={isCommentsLoading || this.state.showCommentListLoader}
                  />
                  {this.renderPagination()}
                </div>
              )}
              {isWixCommentsEnabled && <HorizontalSeparator className={styles.divider} />}
              {isWixCommentsEnabled && <PostPageWixComments post={post} hideHeaderDivider />}
              {this.renderFooter()}
            </AnimatedLoader>
          </AnimatedLoader>
          <aside className={styles.sidebar}>
            <PostPageSidebar post={post} />
          </aside>
        </div>
        {!isSSR && isFooterPostsEnabled && (
          <div className={styles.footerPosts} data-hook="footer-posts-wrapper">
            <FooterPosts type={FooterPosts.TYPE_RELATED_POSTS} />
          </div>
        )}
      </PostPageLayout>
    );
  };
}

PostPage.propTypes = {
  category: PropTypes.object.isRequired,
  post: PropTypes.object.isRequired,
  allComments: PropTypes.array,
  page: PropTypes.number,
  commentToFocusId: PropTypes.string,
  emitClosePost: PropTypes.func.isRequired,
  t: PropTypes.func,
  entityCount: PropTypes.number,
  isAuthenticated: PropTypes.bool,
  isBlocked: PropTypes.bool,
  params: PropTypes.object,
  isAnimated: PropTypes.object,
  borderWidth: PropTypes.number.isRequired,
  titleFontClassName: PropTypes.string.isRequired,
  contentFontClassName: PropTypes.string.isRequired,
  setIsLoaded: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  isLoaded: PropTypes.bool.isRequired,
  isCommentsLoading: PropTypes.bool.isRequired,
  isCommentsLoaded: PropTypes.bool.isRequired,
  navigateWithinForum: PropTypes.func.isRequired,
  clearComments: PropTypes.func.isRequired,
  commentToFocusIdParentId: PropTypes.string,
  isFooterPostsEnabled: PropTypes.bool,
  isSSR: PropTypes.bool,
  changePage: PropTypes.func,
};

const mapRuntimeToProps = (state, ownProps, actions, host) => {
  const params = getRouteParams(state);
  const post = getPostBySlug(state, params.postSlug) || {};
  const page = parseInt(params.page, 10) || 1;
  const { commentToFocusId, parentId } = getDeepLinkParams(params.deepLinkData);
  const prevRoute = get(getPreviousMatches(state)[1], 'route'); // take 1st member, since 0 is the current route
  return {
    isAnimated: [
      ROUTE_CREATE_POST,
      ROUTE_CREATE_QUESTION,
      ROUTE_CATEGORY_CREATE_POST,
      ROUTE_CATEGORY_CREATE_QUESTION,
      ROUTE_POST_EDIT,
    ].includes(prevRoute),
    category: getCategoryBySlug(state, params.categorySlug) || {},
    post,
    allComments: getCommentsByPostId(state, post._id),
    isLoading: getIsPostLoading(state, params.postSlug),
    isLoaded: getIsPostLoaded(state, params.postSlug),
    isCommentsLoading: getIsCommentsLoading(state, params.postSlug),
    isCommentsLoaded: getIsCommentsLoaded(state, params.postSlug),
    entityCount: getEntityCount(state, 'comments', post._id),
    page,
    commentToFocusId,
    commentToFocusIdParentId: parentId,
    emitClosePost: actions.emitClosePost,
    setIsLoaded: actions.setIsLoaded,
    navigateWithinForum: actions.navigateWithinForum,
    clearComments: actions.clearComments,
    changePage: actions.changePage,
    isSSR: isSSR(state),
    params,
    isFooterPostsEnabled: getIsFooterPostsEnabled(state, host.style),
    commentsPerPage: getCommentsPerPage(false),
  };
};

export default flowRight(
  connect(mapRuntimeToProps, [
    REDUCERS.BASIC_PARAMS,
    REDUCERS.ROUTER,
    REDUCERS.CATEGORIES,
    REDUCERS.POSTS,
    REDUCERS.COMMENTS,
    REDUCERS.IS_LOADING,
    REDUCERS.IS_LOADED,
    REDUCERS.PAGINATION,
  ]),
  withFontClassName,
  withCardBorderWidth,
  withAuth,
  withTranslate,
  withExperiment({
    isWixCommentsEnabled: EXPERIMENT_WIX_COMMENTS,
  }),
)(PostPage);
