import { flowRight, memoize, mapKeys, identity } from 'lodash';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { WixComments } from '@wix/comments-ooi-client';
import { connect } from '../../../common/components/runtime-context';
import { OwnerSmallIcon } from '../../components/icons/owner-small-icon';
import withAuth from '../../hoc/with-auth';
import { pluginTypeMap } from './plugins';
import withTranslate from '../../../common/components/with-translate/with-translate';
import { withRcePluginsConfig } from '../../hoc/with-rce-plugins-config';
import withCardBackgroundColor from '../../hoc/with-card-background-color';
import withButtonColor from '../../hoc/with-button-color';
import withSettingsColor from '../../hoc/with-settings-color';
import { APP_TEXT_COLOR_PATH } from '@wix/communities-forum-client-commons/dist/src/constants/wix-params';
import { isSite } from '../../../common/store/basic-params/basic-params-selectors';
import { getIsSocialSharingLinksEnabled } from '../../selectors/app-settings-selectors';
import { buildDeepLink } from '../../services/build-deep-comment-url';
import { getContextToken } from '../../services/context-token';
import withRelativeTimeFormatting from '../../hoc/with-relative-time-formatting';
import { buildCssOverride } from './css-override';

// https://wix.slack.com/archives/CAKBA7TDH/p1601553610052000
const memoizeFn = memoize.Cache === WeakMap ? identity : memoize;
class PostPageWixComments extends Component {
  state = { isInErrorState: false };
  componentDidCatch(error) {
    // internal comments error handler should catch errors, but there still can be cases where error bubbles up
    this.setState({ isInErrorState: true });
    console.error(error);
  }

  getCtxFields = memoizeFn(getContextToken);

  pluginConfig = mapKeys(this.props.editorPluginsConfig, (val, key) => pluginTypeMap[key]);

  formatRelativeTime = time => this.props.formatRelativeTime && this.props.formatRelativeTime(time);

  getPalette = () => {
    const { cardBackgroundColor, textColor, buttonColor } = this.props;
    return {
      bgColor: cardBackgroundColor,
      textColor,
      actionColor: buttonColor,
    };
  };

  ricosTheming = {
    theme: {
      palette: this.getPalette(),
    },
    cssOverride: buildCssOverride(this.props.style),
  };

  getDeepLinkProp = memoizeFn(
    (isLiveSite, isSocialSharingLinksEnabled, generateLink) =>
      isLiveSite &&
      isSocialSharingLinksEnabled && {
        generateLink,
      },
  );

  render() {
    const {
      post,
      t,
      isLiveSite,
      isSocialSharingLinksEnabled,
      generateLink,
      hideHeaderDivider,
      loadEditorPluginsAsync,
    } = this.props;

    if (this.state.isInErrorState) {
      return null;
    }
    return (
      <WixComments
        hideZeroCommentsEmptyState
        pluginConfig={{
          loadEditorPluginsAsync,
          plugins: this.pluginConfig,
          type: 'async',
        }}
        resourceId={post._id}
        ctxFields={this.getCtxFields(post._id)}
        pagination={PAGINATION_CONFIG}
        formatRelativeTime={this.formatRelativeTime}
        isLocked={post.isCommentsDisabled}
        roleIcons={ROLE_ICONS}
        ricosTheming={this.ricosTheming}
        deepLink={this.getDeepLinkProp(isLiveSite, isSocialSharingLinksEnabled, generateLink)}
        t={t}
        hideHeaderDivider={hideHeaderDivider}
      />
    );
  }
}

const ROLE_ICONS = {
  OWNER: OwnerSmallIcon,
  CONTRIBUTOR: OwnerSmallIcon,
};

const PAGINATION_CONFIG = {
  replyShowMoreLimit: 8,
  initialPage: {
    commentLimit: 20,
    replyLimit: 0,
  },
  pagination: {
    commentLimit: 20,
    replyLimit: 0,
  },
  maxPagesBeforeDrop: {
    commentPages: 2,
    replyPages: 4,
  },
};

PostPageWixComments.propTypes = {
  post: PropTypes.object,
  style: PropTypes.object,
  editorPluginsConfig: PropTypes.object.isRequired,
  isLiveSite: PropTypes.bool,
  isSocialSharingLinksEnabled: PropTypes.bool,
  generateLink: PropTypes.func,
  hideHeaderDivider: PropTypes.bool,
};

const mapRuntimeToProps = (state, ownProps, actions, host) => ({
  style: host.style,
  generateLink: commentId => buildDeepLink(state, commentId, ownProps.post._id),
  isLiveSite: isSite(state),
  isSocialSharingLinksEnabled: getIsSocialSharingLinksEnabled(state, host.style),
});

export default flowRight(
  withRelativeTimeFormatting,
  withCardBackgroundColor,
  withButtonColor,
  withSettingsColor({
    path: APP_TEXT_COLOR_PATH,
    propName: 'textColor',
    siteColorFallback: 'color-5',
  }),
  withRcePluginsConfig,
  withAuth,
  withTranslate,
  connect(mapRuntimeToProps),
)(PostPageWixComments);
