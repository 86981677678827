import { flowRight } from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';
import {
  getPostGiphyCover,
  getPostVideoEmbedCover,
  getPostImageCover,
} from '@wix/communities-forum-client-commons/dist/src/services/get-post-cover';
import getOuterUrl from '../../services/get-outer-url';
import withPermissions from '../../hoc/with-permissions';
import { connect } from '../../../common/components/runtime-context';
import {
  getIsPinterestPinEnabled,
  getIsSocialSharingLinksEnabled,
} from '../../selectors/app-settings-selectors';
import VideoEmbed from '../video-embed';
import { Img } from '@wix/communities-image-lib';
import styles from './post-cover-media.scss';
import withExperiment from '../../hoc/with-experiment';
import { EXPERIMENT_PINTEREST_TOGGLE } from '@wix/communities-forum-client-commons/dist/src/constants/experiments';

export const hasCoverMedia = post =>
  getPostImageCover(post) || getPostVideoEmbedCover(post) || getPostGiphyCover(post);

function PostCoverMedia({ post, can, postLink, isPinterestPinEnabled, children, ...restProps }) {
  const image = getPostImageCover(post);
  const videoEmbed = getPostVideoEmbedCover(post);
  const giphyEmbed = getPostGiphyCover(post);

  let media;

  if (videoEmbed) {
    media = <VideoEmbed oembed={videoEmbed} />;
  } else if (image) {
    const props =
      isPinterestPinEnabled && can('read', 'post', post)
        ? { 'data-pin-url': getOuterUrl(postLink) }
        : { 'data-pin-nopin': true };
    media = <Img className={styles.image} src={image} {...props} {...restProps} />;
  } else if (giphyEmbed) {
    media = <img className={styles.image} src={giphyEmbed.originalUrl} alt="gif used in post" />;
  }

  return children(media) || null;
}
PostCoverMedia.propTypes = {
  postLink: PropTypes.string,
  post: PropTypes.object,
  isPinterestPinEnabled: PropTypes.bool,
  can: PropTypes.func,
  children: PropTypes.func,
};

const mapRuntimeToProps = (state, ownProps, actions, host) => ({
  isPinterestPinEnabled: ownProps.isPinterestToggleEnabled
    ? getIsPinterestPinEnabled(state, host.style)
    : getIsSocialSharingLinksEnabled(state, host.style),
});

export default flowRight(
  withExperiment({ isPinterestToggleEnabled: EXPERIMENT_PINTEREST_TOGGLE }),
  withPermissions,
  connect(mapRuntimeToProps),
)(PostCoverMedia);
