import PropTypes from 'prop-types';
import React, { Component } from 'react';
import classNames from 'classnames';

import styles from './modal.scss';
import { handleEscapeKeyUp } from '../../../services/accessibility';
import ModalA11yContext from './modal-a11y-context';
import { scrollBy } from '../../../services/scroll-by';
import withDeviceType from '../../../hoc/with-device-type';

export class ModalPage extends Component {
  constructor(props) {
    super(props);
    this.activeElement = document.activeElement;
    this.preModalFocusWrapper = React.createRef();
    this.postModalFocusWrapper = React.createRef();
    this.initialFocusElement = React.createRef();
    this.titleId = Date.now().toString();
  }

  preventBackgroundScroll = () => {
    if (typeof document !== 'undefined' && this.props.isMobile) {
      this.body = document.getElementsByTagName('body')[0];
      this.prevScrollY = this.body.scrollY;
      setTimeout(() => this.body && this.body.classList.add(styles.noScroll), 200);
    }
  };

  allowBackgroundScroll = () => {
    if (this.body) {
      this.body.classList.remove(styles.noScroll);
      scrollBy(0, this.prevScrollY, false);
    }
  };

  focusInitialElement = () => {
    this.initialFocusElement && this.initialFocusElement.current.focus();
  };

  focusInvokerElement = () => {
    if (this.activeElement) {
      setTimeout(() => this.activeElement.focus());
    }
  };

  componentDidMount() {
    this.focusInitialElement();
    this.addFocusWrapperEventListeners();
    this.preventBackgroundScroll();
  }

  componentWillUnmount() {
    this.allowBackgroundScroll();
    this.focusInvokerElement();
  }

  addFocusWrapperEventListeners = () => {
    this.preModalFocusWrapper.current.addEventListener('focusin', this.focusInitialElement);
    this.postModalFocusWrapper.current.addEventListener('focusin', this.focusInitialElement);
  };

  onClose = () => {
    this.focusInvokerElement();
    this.props.onClose && this.props.onClose();
  };

  render() {
    const { showBackdrop, children, className } = this.props;
    const containerClassName = classNames(className, styles.container, {
      [styles.withShadow]: !showBackdrop,
    });
    return (
      <React.Fragment>
        <div ref={this.preModalFocusWrapper} className={styles.focusWrapper} tabIndex="0" />
        <main
          ref={this.initialFocusElement}
          className={containerClassName}
          onKeyUp={handleEscapeKeyUp(this.onClose)}
          data-hook="modal-page"
          role="dialog"
          tabIndex="0"
          aria-labelledby={this.titleId}
        >
          <ModalA11yContext.Provider value={{ titleId: this.titleId }}>
            {children}
          </ModalA11yContext.Provider>
        </main>
        <div ref={this.postModalFocusWrapper} className={styles.focusWrapper} tabIndex="0" />
      </React.Fragment>
    );
  }
}

ModalPage.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
  showBackdrop: PropTypes.bool,
  isMobile: PropTypes.bool,
};

export default withDeviceType(ModalPage);
