import React from 'react';
import { flowRight, debounce } from 'lodash';
import classNames from 'classnames';
import { CSSTransitionGroup } from 'react-transition-group';
import { connect } from '../../../common/components/runtime-context';
import { isQuestion } from '@wix/communities-forum-client-commons/dist/src/constants/post-types';
import withDividerColor from '../../hoc/with-divider-color';
import withTranslate from '../../../common/components/with-translate/with-translate';
import withFontClassName from '../../hoc/with-font-class-name';
import { getSectionUrl } from '../../../common/store/location/location-selectors';
import { getSimilarPosts, getExternalLinks } from '../../selectors/post-selectors';
import { getCategory } from '../../../common/selectors/categories-selectors';
import {
  getIsSimilarPostsLoading,
  getIsExternalLinksLoading,
} from '../../selectors/is-loading-selectors';
import ExternalLink from '../link/external-link';
import { SolvedIcon } from '../icons/solved-icon';
import { HorizontalSeparator } from '../separator';
import styles from './post-auto-suggestions.scss';
import CounterNumber from '../counter-number';
import { css } from 'emotion';
import withCardBackgroundColor from '../../hoc/with-card-background-color';

const ORIGIN_AUTO_SUGGESTIONS = 'auto_suggest';

const Loader = props => {
  if (props.isLoading) {
    return <div className={styles.status}>{props.loadingMessage}</div>;
  }
  return props.children;
};

class PostAutoSuggestions extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isVisible: false,
    };
    this.componentRef = React.createRef();
  }

  componentDidMount() {
    const { title, isTitleInputFocused } = this.props;
    if (title.length >= 3 && isTitleInputFocused) {
      this.getSuggestedPosts(title);
    }
    document.addEventListener('click', this.handleComponentHideOnEvent);
    document.addEventListener('focusin', this.handleComponentHideOnEvent);
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.handleComponentHideOnEvent);
    document.removeEventListener('focusin', this.handleComponentHideOnEvent);
  }

  componentDidUpdate(prevProps) {
    const { title, isTitleInputFocused, isLoading } = this.props;
    const titleHasUpdated = title !== prevProps.title && title.length >= 3;
    const titleInputHasFocused = isTitleInputFocused !== prevProps.isTitleInputFocused;
    const hasStartedLoading = isLoading !== prevProps.isLoading;

    if (titleHasUpdated) {
      this.getSuggestedPosts(title);
      this.showComponent();
    } else if (titleInputHasFocused || (titleInputHasFocused && hasStartedLoading)) {
      this.showComponent();
    }
  }

  getSuggestedPosts = debounce(value => {
    this.props.fetchSimilarPosts(value);
    this.props.fetchExternalLinks(value);
  }, 300);

  showComponent = () => this.setState({ isVisible: true });

  hideComponent = () => this.setState({ isVisible: false });

  handleComponentHideOnEvent = e => {
    if (this.getIsElementBelongsToComponent(e.target) || this.props.isTitleInputFocused) {
      return;
    }
    this.hideComponent();
  };

  getIsElementBelongsToComponent = element =>
    this.componentRef.current && this.componentRef.current.contains(element);

  renderSimilarPosts = posts => {
    const { getCategory, forumUrl, t } = this.props;
    return (
      <div>
        <div className={styles.sectionHeader}>{t('post-auto-suggestions.similar-posts')}</div>
        {posts.map(post => {
          const { bestAnswerCommentId, title, totalComments, postType } = post;
          const category = getCategory(post.categoryId);
          const postLink = `${forumUrl}/${category.slug}/${post.slug}`;
          const resultCountTranslationKey = isQuestion(postType)
            ? 'post-auto-suggestions.answers-count'
            : 'post-auto-suggestions.comment-count';

          return (
            <div className={styles.itemContainer}>
              {bestAnswerCommentId && (
                <SolvedIcon className={classNames(styles.icon, 'button-fill')} isColored />
              )}
              <div className={styles.title}>
                <ExternalLink
                  to={postLink}
                  className={classNames('forum-text-color', 'forum-link-hover-color', styles.link)}
                  applyButtonColor={false}
                  data-hook="suggestions-similar-post"
                  onClick={() => this.props.similarPostClicked(post)}
                  queryParams={{ origin: ORIGIN_AUTO_SUGGESTIONS }}
                >
                  {title}
                </ExternalLink>
              </div>
              <span className={styles.answerCount}>
                <CounterNumber
                  tKey={resultCountTranslationKey}
                  initialValue={totalComments}
                  entityId={post._id}
                  totalComments
                />
              </span>
            </div>
          );
        })}
      </div>
    );
  };

  renderExternalLinks = posts => (
    <div>
      <div className={styles.sectionHeader}>
        {this.props.t('post-auto-suggestions.external-links')}
      </div>
      {posts.map(post => {
        return (
          <div className={styles.itemContainer}>
            <ExternalLink
              to={post.link}
              className={classNames('forum-text-color', 'forum-link-hover-color', styles.link)}
              onClick={() => this.props.externalResourceClicked(post)}
              applyButtonColor={false}
            >
              <div className={styles.title}>{post.title}</div>
            </ExternalLink>
          </div>
        );
      })}
    </div>
  );

  render() {
    const {
      handleDisableSuggestions,
      isLoading,
      similarPosts,
      externalLinks,
      contentFontClassName,
      isLocalSuggestionsEnabled,
      t,
      cardBackgroundColor,
    } = this.props;

    const { isVisible } = this.state;
    const hasSimilarPosts = Boolean(similarPosts.length);
    const hasExternalLinks = Boolean(externalLinks.length);

    return (
      <CSSTransitionGroup
        component="div"
        transitionName={styles}
        transitionEnterTimeout={100}
        transitionLeaveTimeout={100}
      >
        {isVisible && isLocalSuggestionsEnabled && (
          <div
            className={classNames(
              'forum-card-border-color',
              styles.container,
              css`
                background-color: ${cardBackgroundColor};
              `,
            )}
            ref={this.componentRef}
            key="suggestions-container"
            data-hook="suggestions-container"
          >
            <Loader
              isLoading={isLoading}
              loadingMessage={t('post-auto-suggestions.looking-for-content')}
            >
              <div className={styles.contentContainer}>
                {hasExternalLinks && this.renderExternalLinks(externalLinks)}
                {hasExternalLinks && hasSimilarPosts && (
                  <HorizontalSeparator className={styles.separator} />
                )}
                {hasSimilarPosts && this.renderSimilarPosts(similarPosts)}
                {!hasExternalLinks && !hasSimilarPosts && (
                  <div className={styles.status} data-hook="no-similar-posts">
                    {t('post-auto-suggestions.no-similar-posts')}
                  </div>
                )}
              </div>
            </Loader>
            <HorizontalSeparator />
            <div className={styles.buttonContainer}>
              <button
                className={classNames(styles.button, 'button-color', contentFontClassName)}
                onClick={handleDisableSuggestions}
                data-hook="turn-off-suggestions"
              >
                {t('post-auto-suggestions.turn-off-suggestions')}
              </button>
            </div>
          </div>
        )}
      </CSSTransitionGroup>
    );
  }
}

const mapRuntimeToProps = (state, ownProps, actions) => {
  return {
    fetchSimilarPosts: actions.fetchSimilarPosts,
    fetchExternalLinks: actions.fetchExternalLinks,
    isLoading: getIsSimilarPostsLoading(state) || getIsExternalLinksLoading(state),
    getCategory: id => getCategory(state, id),
    forumUrl: getSectionUrl(state),
    externalLinks: getExternalLinks(state),
    similarPosts: getSimilarPosts(state),
    similarPostClicked: post =>
      actions.buttonClicked({
        name: 'click_similar_post',
        origin: ORIGIN_AUTO_SUGGESTIONS,
        _id: post._id,
        title: post.title,
        slug: post.slug,
        term: ownProps.title,
      }),
    externalResourceClicked: resource =>
      actions.buttonClicked({
        name: 'click_external_resource',
        origin: ORIGIN_AUTO_SUGGESTIONS,
        title: resource.title,
        slug: resource.link,
        term: ownProps.title,
      }),
  };
};

export default flowRight(
  withTranslate,
  withDividerColor,
  withCardBackgroundColor,
  withFontClassName,
  connect(mapRuntimeToProps),
)(PostAutoSuggestions);
