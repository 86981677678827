import PropTypes from 'prop-types';
import React from 'react';
import { get, isEmpty, flowRight } from 'lodash';
import { connect } from '../../../common/components/runtime-context';
import classNames from 'classnames';
import CommentHeader from '../comment-header';
import PostExcerpt from '../post-excerpt';
import withFontClassName from '../../hoc/with-font-class-name';
import { getCategory } from '../../../common/selectors/categories-selectors';
import styles from './comment-search-list-item.scss';
import ContentExcerpt from '../content-excerpt';
import { getIsListHashtagsEnabled } from '../../selectors/app-settings-selectors';
import Hashtags from '../hashtags/hashtags';
import Link from '../link/internal-link';
import CommentListCardHeader from '../card/comment-list-card-header';
import { buildNewDeepCommentUrl } from '../../services/build-deep-comment-url';
import withExperiment from '../../hoc/with-experiment';
import { EXPERIMENT_WIX_COMMENTS } from '@wix/communities-forum-client-commons/dist/src/constants/experiments';

const CommentSearchListItem = ({
  category,
  comment,
  type,
  query,
  onPostLikeClick,
  titleFontClassName,
  contentFontClassName,
  isListHashtagsEnabled,
  resolveCommentDeepLinkUrl,
  isMobile,
  isWixCommentsEnabled,
}) => {
  const commentLink = isWixCommentsEnabled
    ? buildNewDeepCommentUrl(category.slug, comment.post.slug, comment._id)
    : `/main/comment/${comment._id}`;
  const hashtags = get(comment, 'hashtags');
  const showHashtags = isListHashtagsEnabled && !isEmpty(hashtags);

  return (
    <div>
      <article
        className={classNames(
          styles.container,
          'forum-card-background-color',
          'forum-text-color',
          'forum-card-border-color',
          contentFontClassName,
        )}
      >
        <div className={classNames(titleFontClassName, styles.post)}>
          <PostExcerpt
            categorySlug={category.slug}
            post={comment.post}
            onLikeClick={onPostLikeClick}
          />
        </div>
        {isMobile ? (
          <CommentListCardHeader comment={comment} origin="search-page" />
        ) : (
          <CommentHeader
            type={CommentHeader.SEARCH}
            comment={comment}
            showMoreButton
            showMoreButtonEdit={false}
          />
        )}

        <Link
          dynamicLocationResolver={() => resolveCommentDeepLinkUrl(comment._id)}
          to={commentLink}
          className={classNames('forum-link-hover-color', 'forum-text-color')}
        >
          <div className={styles.content}>
            <ContentExcerpt
              className={styles.commentExcerpt}
              type={type}
              content={comment.content}
              query={query}
            />
          </div>
        </Link>
        {showHashtags && <Hashtags hashtags={hashtags} className={styles.hashtags} />}
      </article>
    </div>
  );
};

CommentSearchListItem.propTypes = {
  category: PropTypes.object.isRequired,
  comment: PropTypes.object.isRequired,
  type: PropTypes.string,
  onPostLikeClick: PropTypes.func.isRequired,
  titleFontClassName: PropTypes.string.isRequired,
  contentFontClassName: PropTypes.string.isRequired,
  query: PropTypes.string,
  isListHashtagsEnabled: PropTypes.bool,
  navigation: PropTypes.object,
  resolveCommentDeepLinkUrl: PropTypes.func,
};

const mapRuntimeToProps = (state, ownProps, actions, host) => ({
  category: getCategory(state, get(ownProps, 'comment.post.categoryId')),
  isListHashtagsEnabled: getIsListHashtagsEnabled(state, host.style),
  resolveCommentDeepLinkUrl: actions.resolveCommentDeepLinkUrlPromisified,
});

export default flowRight(
  connect(mapRuntimeToProps),
  withFontClassName,
  withExperiment({ isWixCommentsEnabled: EXPERIMENT_WIX_COMMENTS }),
)(CommentSearchListItem);
