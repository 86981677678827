import { memoize } from 'lodash';
import { withStyles } from '@wix/native-components-infra/dist/es/src/HOC/withStyles/withStyles';
import AppRoot from '../../../app/containers/app-root';
import InvalidAppStatePage from '../../../app/components/invalid-app-state-page';
import { withLayoutUpdate } from '../../../common/hoc/with-layout-update';
import { withInvalidAppState } from '../../../common/hoc/with-invalid-app-state';
import { withDevTools } from '../../../app/components/dev-tools/with-dev-tools';
import { withErrorState } from '../../../common/hoc/with-error-state';

const withStylesConfig = {
  cssPath: ['forum-app-viewer.stylable.bundle.css', 'forum-app-viewer.css'],
  enableMemoization: memoize.Cache !== WeakMap,
};

const FallbackPage = withStyles(InvalidAppStatePage, withStylesConfig);

export default withErrorState(
  withLayoutUpdate(
    withInvalidAppState(withDevTools(withStyles(AppRoot, withStylesConfig)), FallbackPage),
  ),
  FallbackPage,
);
