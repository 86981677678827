import PropTypes from 'prop-types';
import React from 'react';
import { flowRight } from 'lodash';
import classNames from 'classnames';
import { handleEnterKeyUp } from '../../../services/accessibility';
import { XSmall } from '../../icons/x-small';
import styles from './modal.scss';
import withSettingsColor from '../../../hoc/with-settings-color';
import withTranslate from '../../../../common/components/with-translate/with-translate';

export const ModalLayout = ({ className, borderColor, showCloseButton, onClose, children, t }) => {
  const renderCloseButton = onClose => (
    <button
      aria-label={t('modal.close')}
      className={classNames(styles.closeIcon, 'default-main-icon-fill', 'modal__close')}
      onClick={onClose}
      onKeyUp={handleEnterKeyUp(onClose)}
    >
      <XSmall />
    </button>
  );

  return (
    <div
      className={classNames(
        'default-forum-card-background-color',
        'color-5-text',
        styles.content,
        className,
      )}
      data-hook="modal-layout"
      style={{ borderColor }}
    >
      {showCloseButton ? renderCloseButton(onClose) : null}
      {children}
    </div>
  );
};

ModalLayout.propTypes = {
  className: PropTypes.string,
  borderColor: PropTypes.string,
  children: PropTypes.node,
  showCloseButton: PropTypes.bool,
  onClose: PropTypes.func,
};

export default flowRight(
  withTranslate,
  withSettingsColor({
    propName: 'borderColor',
    siteColorFallback: 'color-5',
    siteColorAlpha: 0.2,
    fallbackColor: 'rgba(51, 51, 51, 0.2)',
  }),
)(ModalLayout);
