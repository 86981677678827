import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';

import { handleEnterKeyUp } from '../../../services/accessibility';
import styles from './modal.scss';

const ModalBackdrop = ({ onClose, className }) => (
  <div>
    <div
      className={classNames(className, styles.backdrop, 'modal__overlay')}
      onClick={onClose}
      onKeyUp={handleEnterKeyUp(onClose)}
    />
  </div>
);

ModalBackdrop.propTypes = {
  className: PropTypes.string,
  onClose: PropTypes.func.isRequired,
};

ModalBackdrop.defaultProps = {
  showCloseButton: true,
};

export default ModalBackdrop;
