import PropTypes from 'prop-types';
import React, { Component } from 'react';

import ModalPage from './modal-page';
import ModalBackdrop from './modal-backdrop';
import ModalLayout from './modal-layout';
import { closeModalWithoutCallback } from './services/close-modal-without-callback';
import styles from './modal.scss';

export class ModalFromBottom extends Component {
  handleClose = () => closeModalWithoutCallback(this.props.onClose);

  render() {
    const { className, children, showBackdrop } = this.props;

    return (
      <ModalPage
        className={styles.fromBottom}
        onClose={this.handleClose}
        showBackdrop={showBackdrop}
      >
        {showBackdrop && <ModalBackdrop onClose={this.handleClose} />}
        <ModalLayout className={className} children={children} showCloseButton={false} />
      </ModalPage>
    );
  }
}

ModalFromBottom.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
  showBackdrop: PropTypes.bool,
};

ModalFromBottom.defaultProps = {
  showBackdrop: true,
};

export default ModalFromBottom;
