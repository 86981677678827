import PropTypes from 'prop-types';
import React, { Component } from 'react';
import classNames from 'classnames';
import PostListItem from '../post-list-item';
import CommentSearchListItem from '../comment-search-list-item';
import styles from './search-results-list.scss';
import { getLastPage } from '@wix/communities-forum-client-commons/dist/src/services/pagination';
import LoadMoreMobilePagination from '../load-more-mobile-pagination/load-more-mobile-pagination';
import withTranslate from '../../../common/components/with-translate/with-translate';
import { SEARCH_RESULTS_PER_PAGE_MOBILE } from '../../constants/pagination';
import { getElementsPositionToRootWindow } from '../../services/get-elements-position';
import { HorizontalSeparator } from '../separator';
import AnimatedLoader from '../animated-loader/animated-loader';
import PostListCard from '../card/post-list-card';

const POST = 'post';
const COMMENT = 'comment';

class SearchResultsMobileList extends Component {
  constructor(props) {
    super(props);
    this.containerRef = React.createRef();
  }

  renderComponent(post, query, onPostLikeClick, onCommentLikeClick, isLast) {
    const separator = !isLast && <HorizontalSeparator />;
    switch (post.type) {
      case POST:
        return [
          <PostListCard
            key={post._id}
            post={post}
            searchQuery={query}
            origin="search_results"
            withContentExcerpt
          />,
          separator,
        ];
      case COMMENT:
        return [
          <CommentSearchListItem
            key={post._id}
            type={PostListItem.SEARCH}
            comment={post}
            query={query}
            onCommentLikeClick={onCommentLikeClick}
            onPostLikeClick={onPostLikeClick}
            isMobile
          />,
          separator,
        ];
      default:
        return null;
    }
  }

  renderItem(post, ...rest) {
    return (
      <div
        className={classNames(styles.listItemMobile, 'search-results-list__list-item')}
        key={post._id}
        id={post._id}
      >
        {this.renderComponent(post, ...rest)}
      </div>
    );
  }

  // temporary fix - scroll to top on search result page switching
  scrollToTop() {
    const containerTop = this.containerRef.current.getBoundingClientRect().top;
    if (containerTop < 0) {
      getElementsPositionToRootWindow(this.containerRef.current, { top: true }, y => {
        scrollBy(0, y);
      });
    }
  }

  loadMoreMobile = diff => () => {
    const { search, page } = this.props;
    search({
      size: SEARCH_RESULTS_PER_PAGE_MOBILE,
      offset: (page - 1 + diff) * SEARCH_RESULTS_PER_PAGE_MOBILE,
    });
    this.scrollToTop();
  };

  render() {
    const {
      posts,
      query,
      onPostLikeClick,
      onCommentLikeClick,
      t,
      isLoading,
      searchResultsTotal,
      page,
    } = this.props;

    return (
      <div
        className={classNames(styles.container, 'search-results-list', 'forum-card-border-color')}
        ref={this.containerRef}
      >
        <div className="forum-card-background-color">
          <LoadMoreMobilePagination
            loadMore={this.loadMoreMobile(-1)}
            hasMore={page > 1}
            loadActionText={t('mobile-search-page.load-less')}
            isLoading={isLoading}
            borderBottom={true}
          />
        </div>
        <div className={classNames(styles.wrapper)}>
          <AnimatedLoader isLoading={isLoading} isAnimated>
            {posts.map((post, i) =>
              this.renderItem(
                post,
                query,
                onPostLikeClick,
                onCommentLikeClick,
                i === posts.length - 1,
              ),
            )}
          </AnimatedLoader>
          <div className="forum-card-background-color">
            <LoadMoreMobilePagination
              loadMore={this.loadMoreMobile(1)}
              hasMore={page < getLastPage(searchResultsTotal, SEARCH_RESULTS_PER_PAGE_MOBILE)}
              loadActionText={t('mobile-search-page.load-more')}
              isLoading={isLoading}
            />
          </div>
        </div>
      </div>
    );
  }
}

SearchResultsMobileList.propTypes = {
  onPostLikeClick: PropTypes.func.isRequired,
  onCommentLikeClick: PropTypes.func.isRequired,
  posts: PropTypes.array,
  location: PropTypes.object,
  query: PropTypes.string,
  t: PropTypes.func.isRequired,
  search: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  searchResultsTotal: PropTypes.number.isRequired,
  page: PropTypes.number.isRequired,
};

export default withTranslate(SearchResultsMobileList);
